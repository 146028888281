<template>
  <div id="app">
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <Login v-if="!isLogin"></Login>
    <router-view v-else></router-view>
  </div>
</template>

<script>

import Login from "@/pages/login/Login";
import httpapi from "./assets/javascript/httpapi";
import OrganizationUtils from "@/assets/javascript/organization-utils";

export default {
  name: 'App',
  components: {
    Login,
  },
  mixins: [httpapi],
  data() {
    return {
      isLogin: false,
      isLoading: false,
      accountExtra: null,
      socket: null,
    }
  },
  mounted() {
    this.isLogin = this.$isLogin();
    let that = this;
    if (this.isLogin) {
      this.$connectWebSocket();
    }
    window.eventBus.$on('login', () => {
      that.isLogin = true;
      if (!OrganizationUtils.$getOrganization()) {
        that.$router.push({name: 'organizations_choose'});
      }
      that.$connectWebSocket();
    })
    window.eventBus.$on('logout', () => {
      that.isLogin = false;
      that.$router.push('/');
    })
  },
  destroyed() {
    window.eventBus.$off('login');
    window.eventBus.$off('logout');
  }
}
</script>

<style>
@import "https://cdn.bootcss.com/element-ui/2.13.1/theme-chalk/index.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
  color: #606266;
}

body {
  margin: 0;
  height: 100vh;
  background: #fbfbfb;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 14px;
  color: #606266;
  overflow-x: hidden;
}

.el-popper[x-placement^=left] .popper__arrow {
  display: none;
}

.el-popper[x-placement^=right] .popper__arrow {
  display: none;
}

.el-popper[x-placement^=bottom] .popper__arrow {
  display: none;
}

.el-popper[x-placement^=top] .popper__arrow {
  display: none;
}

.el-popper {
  border-radius: 16px;
  min-width: 220px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-scrollbar__thumb {
  background-color: transparent;
}

.el-scrollbar__wrap::-webkit-scrollbar-thumb:horizontal {
  background-color: transparent !important;
  width: 0px !important;
}

</style>
