<template>
  <div class="ys-button-wrapper" @click="handleClick">
    <div :class="[
      'ys-button',
      type ? 'ys-button--' + type : '',
      size ? 'ys-button-size--' + size : '',
      {
        'is-disabled' : disabled,
        'is-lighting' : lighting,
        'is-round': round,
      }
     ]">
      <slot>{{content}}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "YsButton",
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'normal',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    lighting: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleClick(evt) {
      if (!this.disabled) {
        this.$emit('click', evt)
      }
    }
  }
}
</script>

<style scoped>

.ys-button-wrapper {
  display: inline-block;
}


.ys-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
}

.ys-button:hover {
  filter: brightness(102%);
}

.ys-button.is-disabled {
  filter: opacity(.5);
}

.ys-button--primary {
  background-color: #f091a6;
  color: white;
}

.ys-button--primary.is-lighting {
  box-shadow: 0 0 4px #f091a6;
}

.ys-button--secondary {
  background-color: #f1f1f1;
}

.ys-button--secondary.is-lighting {
  box-shadow: 0 0 4px #bec0c0;
}

.ys-button--danger {
  background-color: #6f1b2e;
  color: white;
}

.ys-button--danger.is-lighting {
  box-shadow: 0 0 4px #f091a6;
}

.ys-button-size--large {
  height: 32px;
  width: inherit;
  border-radius: 24px;
  font-size: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.ys-button-size--normal {
  height: 24px;
  width: inherit;
  border-radius: 16px;
  font-size: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.ys-button-size--small {
  height: 20px;
  width: inherit;
  border-radius: 10px;
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.ys-button-size--large.is-round {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.ys-button-size--normal.is-round {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.ys-button-size--small.is-round {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}


</style>