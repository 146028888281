const NettyFrameModes = {
    /**
     * 拒绝
     */
    PERMISSION_DENIED: 0,

    /**
     * 新即时通讯消息
     */
    NEW_IM_MESSAGE: 1,

    /**
     * 新问卷
     */
    NEW_QUESTIONNAIRE: 2,

    /**
     * 新饮食处方
     */
    NEW_PRESCRIPTION: 3,

    /**
     * 新报告
     */
    NEW_REPORT: 4,

    /**
     * 细腻干预方案
     */
    NEW_SCHEME: 5,

    /**
     * 新的客户预约
     */
    NEW_APPOINTMENT: 6,

    /**
     * 交易信息
     */
    TRADE: 7,

    /**
     * 干预流程发生变化
     */
    PROCEDURE_STATE_CHANGED: 8,
}

export default NettyFrameModes;