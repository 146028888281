const AccountType = {
    COMMON: 0,
    MANAGER: 1,
    SUPER_MANAGER: 2
}

function defaultAccount() {
    return {
        account: null,
        name: null,
        avatar: null,
        locked: false,
        accountType: AccountType.COMMON,
    }
}

function defaultAccountWithPrivateInfo() {
    let account = defaultAccount();
    account.phone = null;
    account.password = null;
    return account;
}

function defaultAccountInfo() {
    return {
        id: null,
        name: null,
        avatar: null,
        messageList: [],
    }
}

const AccountUtils = {
    AccountType: AccountType,
    ACCOUNT_MAX_LENGTH: 32,
    NAME_MAX_LENGTH: 20,
    PASSWORD_MIN_LENGTH: 6,
    PASSWORD_MAX_LENGTH: 18,
    defaultAccount: defaultAccount,
    defaultAccountWithPrivateInfo: defaultAccountWithPrivateInfo,
    defaultAccountInfo: defaultAccountInfo
}


export default AccountUtils;