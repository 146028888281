const AuditingUtils = {
    TYPE_CREATE: 0,
    TYPE_MODIFY: 1,
    TYPE_OPEN: 2,
    TYPE_CLOSE: 3,

    STATE_WAIT: 0,
    STATE_RECALLED: 1,
    STATE_PASSED: 2,
    STATE_REJECTED: 3,
}

export default AuditingUtils;