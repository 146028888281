//import axios from 'axios'
import md5 from 'js-md5'
import AccountUtils from "./account-utils";
import NettyFrameModes from "./NettyFrameModes";

//const BASE_URL = 'http://localhost:8083';

//const BASE_URL = 'https://www.bflikeme.com/nutrition';
//const WEBSOCKET_SERVER = 'wss://www.bflikeme.com:11223/ws';

//const BASE_URL = "https://31617i75b8.imdo.co/nutrition";
//const WEBSOCKET_SERVER = "ws://localhost:11223/ws";

//const BASE_URL = 'http://localhost:8080/nutrition';
//const WEBSOCKET_SERVER = "ws://localhost:11223/ws";

//const BASE_URL = 'http://192.168.0.130/nutrition';
//const WEBSOCKET_SERVER = 'ws://192.168.0.130:11223/ws';

//const BASE_URL = process.env.VUE_APP_BASE_URL;

let hostname = window.location.hostname;
let WEBSOCKET_SERVER;
if (hostname === 'localhost' || /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/.test(hostname)) {
    WEBSOCKET_SERVER = `ws://${hostname}:11223/ws`;
} else {
    WEBSOCKET_SERVER = `wss://${hostname}:11223/ws`
}

let BASE_URL
if (process.env.VUE_APP_BASE_URL) {
    BASE_URL = process.env.VUE_APP_BASE_URL;
} else {
    BASE_URL = window.location.protocol + '//' + window.location.host + '/nutrition';
}

const TIMEOUT = 30000; //30s
const UPLOAD_TIMEOUT = 120000; //2min

const deepCopy = (source) => {
    if (source == null) {
        return undefined;
    } else if (source instanceof Date) {
        return source.toISOString();
    } else if (typeof source === 'object') {
        if (Object.prototype.toString.call(source) === '[object Array]') {
            let result = new Array(source.length);
            for (let n = 0; n < source.length; n++) {
                result[n] = deepCopy(source[n]);
            }
            return result;
        } else {
            let result = {};
            for (let key in source) {
                if (key.startsWith('__')) continue; //忽略所有内部属性
                let value = deepCopy(source[key]);
                if (value != null) {
                    result[key] = value;
                }
            }
            return result;
        }
    } else if (typeof source === 'string') {
        if (source.startsWith('blob:')) return null; //忽略本地资源
        return source;
    } else {
        return source;
    }
}

const formAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    timeout: TIMEOUT,
    transformRequest: [
        function (data) {
            let newData = '';
            for (let k in data) {
                // eslint-disable-next-line no-prototype-builtins
                if (data.hasOwnProperty(k) === true) {
                    if (data[k] != null) {
                        newData += encodeURIComponent(k) + '=';
                        if (typeof data[k] == 'object') {
                            newData += encodeURIComponent(JSON.stringify(data[k])) + '&';
                        } else {
                            newData += encodeURIComponent(data[k]) + '&';
                        }
                    }
                }
            }
            return newData;
        }
    ]
});

let jsonAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
    timeout: TIMEOUT,
})

/**let uploadAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data'
    },
    timeout: UPLOAD_TIMEOUT
})**/

const Errcode = {
    /**
     * 正确
     */
    OK: 0,

    /**
     * 一般性系统错误
     */
    SYSTEM_ERROR: 10000,

    /**
     * 无效参数
     */
    INVALID_PARAMETERS: 10001,

    /**
     * 账号已存在
     */
    ACCOUNT_EXISTS: 20001,

    /**
     * 账号不存在
     */
    ACCOUNT_NOT_EXISTS: 20003,

    /**
     * 密码错误
     */
    INVALID_PASSWORD: 20004,

    /**
     * 无效登录方式
     */
    INVALID_LOGIN_MODE: 20005,

    /**
     * 无效token
     */
    INVALID_TOKEN: 20006,

    /**
     * 无效刷新token
     */
    INVALID_REFRESH_TOKEN: 20007,

    /**
     * 无权限访问
     */
    PERMISSION_DENIED: 20008,

    /**
     * 数据数量限制
     */
    DATA_COUNT_LIMIT: 30001,

    /**
     * 数据不存在
     */
    DATA_NOT_EXISTS: 30002,

    /**
     * 数据已存在
     */
    DATA_EXISTS: 30003,

    /**
     * 无效上传token
     */
    INVALID_UPLOAD_TOKEN: 30004,

    /**
     * 上传失败
     */
    UPLOAD_FAILED: 30005,

    /**
     * 无效状态
     */
    INVALID_STATE: 30006,

    /**
     * 组织成员不存在
     */
    ORGANIZATION_MEMBER_NOT_EXISTS: 40001,

    /**
     * 组织成员已存在
     */
    ORGANIZATION_MEMBER_EXISTS: 40002,

}

export const RequestType = {
    X_WWW_FORM_URLENCODED: 0,
    JSON: 1,
}

const RequestMethod = {
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    GET: 'GET',
    DELETE: 'DELETE'
}

const BEARER = "Bearer";

const STORAGE_KEYS = {
    ACCOUNT: "account",
    TOKEN: "token",
    ACCOUNT_EXTRA: "account_extra"
}

const toFullUrl = (url) => {
    if (!url) return url;
    if (url.startsWith('.')) return url;
    if (url.startsWith('http://') || url.startsWith('https://')) return url;
    return BASE_URL + url;
}

const prepareImageUrl = (image, width, height) => {
    if (!image) return null;
    let url = typeof image === 'object' ? image.url : image;
    if (!url) return url;
    if (url.startsWith('blob:')) return url;
    let token = doGetToken();
    let params = '';
    if (token) {
        params += 'token=' + encodeURIComponent(token.token);
    }
    if (width) {
        if (params.length > 0) params += '&';
        params += 'w=' + encodeURIComponent(width);
    }
    if (height) {
        if (params.length > 0) params += '&';
        params += 'h=' + encodeURIComponent(height);
    }
    if (params.length > 0) {
        return toFullUrl(url) + "?" + params;
    } else {
        return toFullUrl(url);
    }
}

const createDefaultRequestHandler = () => {
    let that = this;
    return {
        __loadingCode: null,
        __success: [],
        __error: [],
        __finally: [],
        __progress: [],
        __cancelToken: null,
        __delegate: null,
        __promise: null,
        returnWhenCodeMatches() {
            this.__loadingCode = that?.loadingCode;
            return this;
        },
        then(handler) {
            if (typeof that == 'undefined') {
                this.__success.push(handler);
            } else {
                this.__success.push(handler.bind(that));
            }
            return this;
        },
        catch(handler) {
            if (typeof that == 'undefined') {
                this.__error.push(handler);
            } else {
                this.__error.push(handler.bind(that));
            }
            return this;
        },
        complete(handler) {
            if (typeof that == 'undefined') {
                this.__finally.push(handler);
            } else {
                this.__finally.push(handler.bind(that));
            }
            return this;
        },
        progress(handler) {
            if (typeof that == 'undefined') {
                this.__progress.push(handler);
            } else {
                this.__progress.push(handler.bind(that));
            }
            return this;
        },
        cancel() {
            if (typeof this.__cancelToken === 'function') this.__cancelToken();
            if (!this.__delegate) this.__delegate.cancel();
            return this;
        },
        onSuccess(res) {
            if (!this.__delegate) {
                for (let s of this.__success) {
                    if (typeof s === 'function') {
                        if (this.__loadingCode != null && that?.loadingCode !== this.__loadingCode) {
                            continue;
                        }
                        s(res);
                    }
                }
            } else {
                this.__delegate.onSuccess(res);
            }
        },
        onError(res) {
            if (!this.__delegate) {
                for (let e of this.__error) {
                    if (typeof e === 'function') {
                        if (this.__loadingCode != null && that?.loadingCode !== this.__loadingCode) {
                            continue;
                        }
                        e(res);
                    }
                }
            } else {
                this.__delegate.onError(res);
            }
        },
        onComplete() {
            if (!this.__delegate) {
                for (let f of this.__finally) {
                    if (typeof f === 'function') {
                        if (this.__loadingCode != null && that?.loadingCode !== this.__loadingCode) {
                            continue;
                        }
                        f();
                    }
                }
            } else {
                this.__delegate.onComplete();
            }
        },
        onProgress(progress) {
            if (!this.__delegate) {
                for (let p of this.__progress) {
                    if (typeof p === 'function') {
                        if (this.__loadingCode != null && that?.loadingCode !== this.__loadingCode) {
                            continue;
                        }
                        p(progress);
                    }
                }
            } else {
                this.__delegate.onProgress(progress);
            }
        },
        delegate(response) {
            this.__delegate = response;
            response.__promise = this.__promise;
            return this;
        },
        promise() {
            return this.__promise;
        }
    }
}

async function doRequestSync({axios, method, path, params, token, contentType, response}) {
    let error = null;
    try {
        let res = await axios({
            url: path,
            method: method,
            data: method == RequestMethod.POST || method == RequestMethod.PUT || method == RequestMethod.PATCH ? params : null,
            params: method == RequestMethod.GET || method == RequestMethod.DELETE ? params : null,
            headers: {
                Authorization: token ? `${BEARER} ${token}` : null,
                "Content-Type": contentType,
            }
        })

        if (res.data.errcode == Errcode.OK) {
            response.onSuccess(res.data);
        } else {
            error = res.data;
        }
    } catch (e) {
        console.error(e);
        error = {errcode: -1, errmsg: '本地错误'};
    }
    if (error) {
        response.onError(error);
    }
    response.onComplete();

    if (error) {
        throw new Error("http request failed");
    }
}

const doRequest = ({method, path, params, token, type}) => {
    let response = createDefaultRequestHandler();
    let axios = null;
    if (typeof type == 'undefined') {
        type = RequestType.X_WWW_FORM_URLENCODED;
        if (params) {
            for (let key in params) {
                if (typeof params[key] === 'object') {
                    let value = params[key]
                    if(value && value.value && value.digits) { //如果是ExactNumber类型，则不作为object考虑
                    } else {
                        type = RequestType.JSON;
                    }
                }
            }
        }
    }

    if (typeof method == 'undefined') method = RequestMethod.GET;

    let contentType = null;

    path = path.replaceAll('%2F', '%252F');

    switch (type) {
        case RequestType.X_WWW_FORM_URLENCODED:
            contentType = 'application/x-www-form-urlencoded;charset=utf-8';
            axios = formAxios;
            break;
        case RequestType.JSON:
            contentType = "application/json;charset=utf-8";
            axios = jsonAxios;
            break;
        default:
            contentType = type;
            axios = jsonAxios;
            break;
    }

    params = deepCopy(params);

    let promise = doRequestSync({
        axios,
        method,
        path,
        params,
        token,
        contentType,
        response
    });

    response.__promise = promise;

    return response;
}

const doUpload = ({path, token, uploadToken, file}) => {
    let response = createDefaultRequestHandler();
    let formData = new FormData();
    formData.append('file', file.raw, file.name);
    formData.append('token', uploadToken);

    axios.post(
        path,
        formData,
        {
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: token ? `${BEARER} ${token}` : null
            },
            timeout: UPLOAD_TIMEOUT,
            cancelToken: new axios.CancelToken(cancelToken => {
                response.__cancelToken = cancelToken;
            }),
            onUploadProgress: function (e) {
                let progress = parseInt(e.loaded * 100 / e.total);
                response.onProgress(progress);
            }
        }
    ).then(res => {
        try {
            if (res.data.errcode == Errcode.OK) {
                response.onSuccess(res.data);
            } else {
                response.onError(res.data);
            }
            response.onComplete();
        } catch (e) {
            console.error(e);
        }
    }).catch(() => {
        response.onError({errcode: -1});
        response.onComplete();
        console.error('网络连接失败');
    });
    return response;
}

const doSaveAccountToken = (accountToken) => {
    window.localStorage.setItem(STORAGE_KEYS.ACCOUNT, JSON.stringify(accountToken.account));
    let token = {
        token: accountToken.token,
        expiredAt: accountToken.expiredAt,
        refreshToken: accountToken.refreshToken,
        clientType: accountToken.clientType
    }
    window.localStorage.setItem(STORAGE_KEYS.TOKEN, JSON.stringify(token));
}

const doClearAccountToken = () => {
    window.localStorage.removeItem(STORAGE_KEYS.ACCOUNT);
    window.localStorage.removeItem(STORAGE_KEYS.TOKEN);
}

const doSaveAccountExtraMessage = (accountExtra) => {
    window.eventBus.$emit('account_extra', accountExtra);
    window.localStorage.setItem(STORAGE_KEYS.ACCOUNT_EXTRA, JSON.stringify(accountExtra));
}

const doGetAccount = () => {
    let accountStr = window.localStorage.getItem(STORAGE_KEYS.ACCOUNT);
    if (accountStr) {
        return JSON.parse(accountStr);
    }
    return null;
}

const doGetToken = () => {
    let tokenStr = window.localStorage.getItem(STORAGE_KEYS.TOKEN);
    if (tokenStr) {
        let token = JSON.parse(tokenStr);
        if (new Date(token.expiredAt).getTime() <= new Date().getTime()) return null;
        return token;
    }
    return null;
}

const doGetRefreshToken = () => {
    let tokenStr = window.localStorage.getItem(STORAGE_KEYS.TOKEN);
    if (tokenStr) {
        let token = JSON.parse(tokenStr);
        return token;
    }
    return null;
}

const doGetAccountExtra = () => {
    let accountExtraStr = window.localStorage.getItem(STORAGE_KEYS.ACCOUNT_EXTRA);
    return accountExtraStr ? JSON.parse(accountExtraStr) : null;
}

const doIsLogin = () => {
    return doGetAccount() != null;
}

const doIsSuperMgr = () => {
    let account = doGetAccount();
    if (account == null) return false;
    return account.type == AccountUtils.AccountType.SUPER_MANAGER;
}

const doIsMgr = () => {
    let account = doGetAccount();
    if (account == null) return false;
    return account.type == AccountUtils.AccountType.SUPER_MANAGER || account.type == AccountUtils.AccountType.MANAGER;
}

const doLogin = ({account, password}) => {
    let response = createDefaultRequestHandler();
    doRequest({
        method: RequestMethod.POST,
        path: '/auth/login',
        params: {
            account: account,
            password: md5(password)
        }
    }).then(res => {
        res.data.account.account = account;
        doSaveAccountToken(res.data)
        window.eventBus.$emit('login', res.data.account)
    })
        .then(res => {
            response.onSuccess(res);
        })
        .catch(res => {
            response.onError(res);
        })
        .complete(() => {
            response.onComplete();
        })
    return response;
}

const doLogout = () => {
    //let response = createDefaultRequestHandler();
    //let token = doGetToken();
    //let account = doGetAccount();
    let emitLogout = () => {
        window.eventBus.$emit('logout');
    }

    doClearAccountToken();
    emitLogout();
}

const doRefreshToken = () => {
    let response = createDefaultRequestHandler();
    let account = doGetAccount();
    let refreshToken = doGetRefreshToken();

    doRequest({
        method: RequestMethod.POST,
        path: '/auth/token/refresh',
        params: {
            accountId: account.id,
            clientType: refreshToken.clientType,
            refreshToken: refreshToken.refreshToken,
        }
    })
        .then(res => {
            doSaveAccountToken(res.data);
        })
        .then(res => {
            response.onSuccess(res);
        })
        .catch(res => {
            if (res.errcode == Errcode.INVALID_REFRESH_TOKEN) {
                doClearAccountToken();
                window.eventBus.$emit('logout');
            }
        })
        .catch(res => {
            response.onError(res);
        })
        .complete(() => {
            response.onComplete();
        })
    return response;
}

const doAppendListAfter = (list1, list2, id = "id") => {
    if (list2.length == 0) return;
    for (let item of list2) {
        let duplicatedIndex = -1;
        for (let n = 0; n < list1.length; n++) {
            if (item[id] == list1[n][id]) {
                duplicatedIndex = n;
                break;
            }
        }
        if (duplicatedIndex >= 0) {
            list1.splice(duplicatedIndex, 1, item);
        } else {
            list1.push(item);
        }
    }
}

const doAppendListBefore = (list1, list2) => {
    if (list2.length == 0) return;
    for (let index = list2.length - 1; index >= 0; index--) {
        let item = list2[index];
        let duplicatedIndex = -1;
        for (let n = 0; n < list1.length; n++) {
            if (item.id === list1[n].id) {
                duplicatedIndex = n;
                break;
            }
        }
        if (duplicatedIndex >= 0) {
            list1.splice(duplicatedIndex, 1, item);
        } else {
            list1.splice(0, 0, item);
        }
    }
}

function doAppendListByOrder(list1, list2, key = "id", order = "ASC") {
    if (list2.length === 0) return;
    for (let index = 0; index < list2.length; index++) {
        let item = list2[index];
        let isDuplicated = false;
        let insertIndex = -1;
        for (let n = 0; n < list1.length; n++) {
            if (item[key] === list1[n][key]) {
                insertIndex = n;
                isDuplicated = true;
                break;
            } else if (order === 'ASC') {
                if (item[key] < list1[n][key]) {
                    insertIndex = n;
                    break;
                }
            } else if (order === 'DESC') {
                if (item[key] > list1[n][key]) {
                    insertIndex = n;
                    break;
                }
            }
        }
        if (insertIndex < 0) {
            insertIndex = list1.length;
        }
        list1.splice(insertIndex, isDuplicated ? 1 : 0, item);
    }
}

function doPost({path, data, type}) {
    let response = createDefaultRequestHandler();
    let token = doGetToken();
    let request = () => {
        doRequest({
            method: RequestMethod.POST,
            path: path,
            params: data,
            token: doGetToken().token,
            type,
        }).delegate(response);
    }
    if (!token) {
        doRefreshToken()
            .then(request)
            .catch(res => {
                    try {
                        response.onError(res)
                    } finally {
                        response.onError();
                    }
                }
            );
    } else {
        request();
    }
    return response;
}

function doPostJSON({path, data}) {
    let response = createDefaultRequestHandler();
    let token = doGetToken();
    let request = () => {
        doRequest({
            method: RequestMethod.POST,
            path: path,
            params: data,
            token: doGetToken().token,
            type: RequestType.JSON
        }).delegate(response);
    }
    if (!token) {
        doRefreshToken()
            .then(request)
            .catch(res => {
                    try {
                        response.onError(res)
                    } finally {
                        response.onError();
                    }
                }
            );
    } else {
        request();
    }
    return response;
}

function doPut({path, data, type}) {
    let response = createDefaultRequestHandler();
    let token = doGetToken();
    let request = () => {
        doRequest({
            method: RequestMethod.PUT,
            path: path,
            params: data,
            token: doGetToken().token,
            type,
        }).delegate(response);
    }
    if (!token) {
        doRefreshToken()
            .then(request)
            .catch(res => {
                try {
                    response.onError(res);
                } finally {
                    response.onError();
                }
            })
    } else {
        request();
    }
    return response;
}

function doPatch({path, data}) {
    let response = createDefaultRequestHandler();
    let token = doGetToken();
    let request = () => {
        doRequest({
            method: RequestMethod.PATCH,
            path: path,
            params: data,
            token: doGetToken().token,
        })
            .delegate(response);
    }
    if (!token) {
        doRefreshToken().then(request)
            .catch(res => {
                try {
                    response.onError(res);
                } finally {
                    response.onError();
                }
            });
    } else {
        request();
    }
    return response;
}

function doDelete({path, data}) {
    let response = createDefaultRequestHandler();
    let token = doGetToken();
    let request = () => {
        doRequest({
            method: RequestMethod.DELETE,
            path,
            params: data,
            token: doGetToken().token,
        })
            .delegate(response);
    }
    if (!token) {
        doRefreshToken().then(request)
            .catch(res => {
                try {
                    response.onError(res);
                } finally {
                    response.onError();
                }
            })
    } else {
        request();
    }
    return response;
}

function doGet({path, data}) {
    let response = createDefaultRequestHandler();
    let token = doGetToken();
    let request = () => {
        doRequest({
            method: RequestMethod.GET,
            path: path,
            params: data,
            token: doGetToken().token,
        }).delegate(response);
    }
    if (!token) {
        doRefreshToken().then(request)
            .catch(res => {
                    try {
                        response.onError(res)
                    } finally {
                        response.onError();
                    }
                }
            );
    } else {
        request();
    }
    return response;
}

function doUploadImage({imageFile}) {
    let response = createDefaultRequestHandler();
    let accountToken = doGetToken();
    let request = () => {
        doUpload({
            path: '/upload/image',
            token: doGetToken().token,
            file: imageFile
        }).delegate(response);
    }
    if (!accountToken) {
        doRefreshToken()
            .then(request)
            .catch(res => {
                    try {
                        response.onError(res)
                    } finally {
                        response.onError();
                    }
                }
            );
    } else {
        request();
    }
    return response;
}

function doUploadFile({file}) {
    let response = createDefaultRequestHandler();
    let accountToken = doGetToken();
    let request = () => {
        doUpload({
            path: '/upload/file',
            token: doGetToken().token,
            file: file,
        }).delegate(response);
    }

    if (!accountToken) {
        doRefreshToken()
            .then(request)
            .catch(res => {
                    try {
                        response.onError(res)
                    } finally {
                        response.onError();
                    }
                }
            );
    } else {
        request();
    }
    return response;
}

function doLoadAccountExtra() {
    let response = createDefaultRequestHandler();
    this.$reqGet({
        path: '/account/extra/get',
        data: {
            id: doGetAccount().id
        }
    })
        .then(res => {
            doSaveAccountExtraMessage(res.data);
        })
        .then(res => {
            response.onSuccess(res);
        })
        .catch(res => {
            response.onError(res);
        }).complete(() => {
        response.onComplete();
    });
    return response;
}

function doConnectWebSocket() {

    if (this.socket && !this.socket.isClosed) return;

    let request = () => {
        if (this.socket && !this.socket.isClosed) return;

        let token = doGetToken().token;
        console.log('web socket: ' + WEBSOCKET_SERVER)
        let url = `${WEBSOCKET_SERVER}?token=${encodeURIComponent(token)}`;
        try {
            let ws = new WebSocket(url);
            ws.onopen = function () {
                window.eventBus.$emit('notify:connected');
            }
            ws.onmessage = function (e) {
                let data = JSON.parse(e.data);
                setTimeout(() => {
                    let event = data.data;
                    switch (data.mode) {
                        case NettyFrameModes.NEW_IM_MESSAGE:
                            window.eventBus.$emit('notify:message', event);
                            break;
                        case NettyFrameModes.NEW_QUESTIONNAIRE:
                            window.eventBus.$emit('notify:questionnaire', event);
                            break;
                        case NettyFrameModes.NEW_APPOINTMENT:
                            window.eventBus.$emit('notify:appointment', event);
                            break;
                        case NettyFrameModes.TRADE:
                            window.eventBus.$emit('notify:trade', event);
                            break;
                        case NettyFrameModes.PROCEDURE_STATE_CHANGED:
                            window.eventBus.$emit('notify:procedure', event);
                            break;
                    }
                }, 200);
            }
            let that = this;
            ws.onerror = function () {
                console.log('web socket error');
                that.socket.isClosed = true;
                doReconnectWebSocket.bind(that)();
            }
            ws.onclose = function () {
                console.log('web socket closed');
                that.socket.isClosed = true;
                doReconnectWebSocket.bind(that)();
            }

            this.socket = ws;

        } catch (e) {
            doReconnectWebSocket.bind(this)();
        }
    };
    request = request.bind(this);

    let accountToken = doGetToken();
    if (!accountToken) {
        doRefreshToken()
            .then(request);
    } else {
        request();
    }
}

function doReconnectWebSocket() {
    let that = this;
    setTimeout(() => {
        if (doIsLogin()) {
            doConnectWebSocket.bind(that)();
        }
    }, 5000);
}

function doDisconnectWebSocket() {
    if (this.socket && !this.socket.isClosed) {
        console.log('disconnect socket');
        this.socket.close();
    }
}

function doShowBusy() {
    if (this && this.$message && typeof this.$message.warning == 'function') {
        this.$message.warning('操作频繁，请稍后再试');
    }
}


export default {
    data() {
        return {
            loadingCode: 1, //用于数据同步
            Errcode: Errcode,
            socket: null,
        }
    },
    methods: {
        $toFullUrl: toFullUrl,
        $prepareImageUrl: prepareImageUrl,
        $login: doLogin,
        $logout: doLogout,
        $refreshToken: doRefreshToken,
        $getAccount: doGetAccount,
        $getToken: doGetToken,
        $isLogin: doIsLogin,
        $getAccountExtra: doGetAccountExtra,
        $isMgr: doIsMgr,
        $isSuperMgr: doIsSuperMgr,
        $appendAfter: doAppendListAfter,
        $appendBefore: doAppendListBefore,
        $appendByOrder: doAppendListByOrder,
        $reqPost: doPost,
        $reqPostJSON: doPostJSON,
        $reqPut: doPut,
        $reqPatch: doPatch,
        $reqDelete: doDelete,
        $reqGet: doGet,
        $uploadImage: doUploadImage,
        $uploadFile: doUploadFile,
        $loadAccountExtra: doLoadAccountExtra,
        $connectWebSocket: doConnectWebSocket,
        $disconnectWebSocket: doDisconnectWebSocket,
        $showBusy: doShowBusy,

    }
}

