var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ys-button-wrapper",on:{"click":_vm.handleClick}},[_c('div',{class:[
    'ys-button',
    _vm.type ? 'ys-button--' + _vm.type : '',
    _vm.size ? 'ys-button-size--' + _vm.size : '',
    {
      'is-disabled' : _vm.disabled,
      'is-lighting' : _vm.lighting,
      'is-round': _vm.round,
    }
   ]},[_vm._t("default",[_vm._v(_vm._s(_vm.content))])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }