<template>
  <div class="ys-input-wrapper">
    <el-input
        ref="input"
        :class="[
          'ys-input',
          size ? 'ys-input--size-' + size : '',
          {
            'is-disabled': disabled,
            'is-dark': dark,
            'is-hollow': hollow,
            'is-hide-background': !showBackground,
            'is-focused': focused,
          }
      ]"
        v-bind="$attrs"
        :tabindex="tabindex"
        type="text"
        :show-password="showPassword"
        :disabled="disabled"
        :readonly="readonly"
        :auto-complete="autocomplete"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        :label="label"
        v-model="value"
        :prefix-icon="prefixIcon"
        :suffix-icon="suffixIcon"
    >
      <slot slot="prefix" name="prefix"></slot>
      <slot slot="suffix" name="suffix"></slot>
      <slot slot="append" name="append"></slot>
    </el-input>
  </div>
</template>

<script>
export default {
  name: "YsInput",
  props: {
    dark: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'normal',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [String, Number],
    resize: String,
    form: String,
    readonly: Boolean,
    autosize: {
      type: [Boolean, Object],
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    validateEvent: {
      type: Boolean,
      default: true
    },
    suffixIcon: String,
    prefixIcon: String,
    label: String,
    clearable: {
      type: Boolean,
      default: false
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },
    hollow: {
      type: Boolean,
      default: false,
    },
    showBackground: {
      type: Boolean,
      default: true,
    },
    tabindex: String
  },
  data() {
    return {
      focused: false,
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    handleInput(value) {
      this.$emit('input', value);
    },
    handleChange(value) {
      this.$emit('change', value);
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
    }
  }
}
</script>

<style scoped>

.ys-input-wrapper {
  display: inline-block;
}


.ys-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.ys-input.is-focused {
  border: 2px solid #f091a6;
  box-shadow: 0 0 8px #f091a6 !important;
}

.ys-input.is-disabled {
  opacity: 0.5;
}

.ys-input.is-hollow {
  box-shadow: 0 0 2px #dfdfdf inset;
}

.ys-input.is-dark {
  background-color: #f1f1f1;
}

.ys-input.is-dark.is-hollow {
  box-shadow: 0 0 2px #bec0c0 inset;
}

.ys-input.is-hide-background, ys-input.is-focused.is-hide-background {
  box-shadow: none !important;
  background-color: transparent !important;
  border: none;
}

.ys-input--size-normal {
  height: 32px;
  line-height: 1.5em;
  width: inherit;
  border-radius: 18px;
  font-size: 10px;
}

.ys-input--size-normal>>>.el-input__icon {
  line-height: 32px;
}

.ys-input--size-normal>>>.el-input__inner {
  background-color: #ff5500;
}

.ys-input--size-large {
  height: 32px;
  width: inherit;
  border-radius: 20px;
  font-size: 12px;
}

.ys-input--size-extra-large {
  height: 48px;
  line-height: 48px;
  width: inherit;
  border-radius: 24px;
  font-size: 14px;
}

.ys-input--size-large>>>.el-input__icon {
  line-height: 32px;
}

.ys-input--size-large>>>.el-input__inner {
  height: 40px;
}

.ys-input--size-extra-large>>>.el-input__icon {
  line-height: 48px;
}

.ys-input--size-extra-large>>>.el-input__inner {
  height: 48px;
}

.ys-input >>> .el-input__inner {
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  text-align: inherit;
}


</style>