//import Vue from 'vue'
//import VueRouter from 'vue-router'
import App from './App.vue'
import routers from "@/router";
//import axios from 'axios';
import moment from "moment";
import infiniteScroll from 'vue-infinite-scroll';
import VueClipboard from "vue-clipboard2";
import en from "element-ui/src/locale/lang/en";

import ELEMENT from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Date.prototype.toISOString = function () {
    return moment(this).format('YYYY-MM-DDTHH:mm:ss');
}


Vue.use(VueRouter)
Vue.use(ELEMENT)
Vue.use(infiniteScroll);
Vue.use(VueClipboard);

Vue.config.productionTip = false
Vue.prototype.$axios = axios;

window.eventBus = new Vue();

const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
        let binding = entry.target.binding;
        if(binding) {
            let rect = entry.target.getBoundingClientRect();
            binding.value({width: rect.width, height: rect.height});
        }
    }
});

Vue.directive('resize', {
    bind(el, binding) {
        el.binding = binding;
        resizeObserver.observe(el);
    },
    unbind(el) {
        delete el.binding;
        resizeObserver.unobserve(el);
    },
});

const router = new VueRouter({
    mode: "hash",
    routes: routers
});

new Vue({
    render: h => h(App),
    router
}).$mount('#app')
