const routers = [
    {
        path: '/',
        name: 'home',
        redirect: 'organizations_choose',
    },
    {
        path: '/test',
        name: 'test',
        component: () => import("./pages/test/WedgetTestPage"),
    },
    {
        path: '/organizations_choose',
        name: 'organizations_choose',
        component: () => import("./pages/organization/OrganizationChoosePage")
    },
    {
        path: '/organization_content',
        name: 'organization_content',
        component: () => import('./pages/organization/OrganizationContentManagement'),
        redirect: '/organization_content/questionnaire_list',
        children: [
            {
                path: 'questionnaire_list',
                name: 'questionnaire_list',
                component: () => import("./pages/questionnaire/QuestionnaireList"),
                meta: {index: 1, keepAlive: true},
            },
            {
                path: 'questionnaire',
                name: 'questionnaire',
                component: () => import("./pages/questionnaire/Questionnaire"),
                meta: {index: 2, keepAlive: true},
            },
            {
                path: 'punch_list',
                name: 'punch_list',
                component: () => import("./pages/punch/PunchList"),
                meta: {index: 1, keepAlive: true}
            },
            {
                path: 'punch',
                name: 'punch',
                component: () => import('./pages/punch/Punch'),
                meta: {index: 2, keepAlive: true}
            },
            {
                path: 'report_template_list',
                name: 'report_template_list',
                component: () => import('./pages/report/ReportList'),
                meta: {index: 1, keepAlive: true}
            },
            {
                path: 'report_template',
                name: 'report_template',
                component: () => import('./pages/report/Report'),
                meta: {index: 2, keepAlive: true}
            },
            {
                path: 'scheme_list',
                name: 'scheme_list',
                component: () => import("./pages/scheme/SchemeList"),
                meta: {index: 1, keepAlive: true},
            },
            {
                path: 'scheme',
                name: 'scheme',
                component: () => import('./pages/scheme/Scheme'),
                meta: {index: 2, keepAlive: true}
            },
            {
                path: 'cookery_book_list',
                name: 'cookery_book_list',
                component: () => import('./pages/cookerybook/CookeryBookList'),
                meta: {index: 1, keepAlive: true}
            },
            {
                path: 'cookery_book_diet_list',
                name: 'cookery_book_diet_list',
                component: () => import('./pages/cookerybook/CookeryBookDietList'),
                meta: {index: 2, keepAlive: true}
            },
            {
                path: 'cookery_book',
                name: 'cookery_book',
                component: () => import('./pages/cookerybook/CookeryBook'),
                meta: {index: 3, keepAlive: true},
            },
        ]
    },
    {
        path: '/users',
        name: 'users',
        component: () => import("./pages/user/Users"),
        children: [
            {
                path: 'punch',
                name: 'user_punch_mgmt',
                component: () => import("./pages/user/punch/PunchPanel"),
            },
            {
                path: 'questionnaire',
                name: 'user_questionnaire_mgmt',
                component: () => import("./pages/user/questionnaire/QuestionnairePanel"),
            },
            {
                path: 'prescription',
                name: 'user_prescription_mgmt',
                component: () => import("./pages/user/prescription/PrescriptionPanel"),
            },
            {
                path: 'report',
                name: 'user_report_mgmt',
                component: () => import('./pages/user/report/ReportPanel'),
            },
            {
                path: 'consultancy',
                name: 'user_consultancy',
                component: () => import('./pages/user/consultancy/ConsultancyPanel')
            },
            {
                path: 'trade',
                name: 'user_trade_mgmt',
                component: () => import('./pages/user/trade/TradePanel')
            },
            {
                path: 'minitool',
                name: 'user_minitool',
                component: () => import('./pages/user/minitool/MiniToolPanel')
            },
            {
                path: 'procedure',
                name: 'user_procedure_mgmt',
                component: () => import('./pages/user/procedure/ProcedurePanel'),
            },
            {
                path: 'cookery_book_list',
                name: 'user_cookery_book_mgmt',
                component: () => import('./pages/user/cookerybook/CookeryBookListPanel'),
            },
            {
                path: 'cookery_book',
                name: 'user_cookery_book',
                component: () => import('./pages/user/cookerybook/CookerBook'),
            },
            {
                path: 'user_remark_list',
                name: 'user_remark_list',
                component: () => import('./pages/user/userremark/UserRemarkListPanel'),
            }
        ]
    },
    {
        path: '/user_mgmt',
        name: 'user_mgmt',
        component: () => import('./pages/user_bak/UserManagement'),
        redirect: '/user_mgmt/user_list',
        children: [
            {
                path: 'user_list',
                name: 'user_list',
                component: () => import('./pages/user_bak/UserList'),
                meta: {index: 1, keepAlive: true},
            },
            {
                path: 'user',
                name: 'user',
                component: () => import('./pages/user_bak/User'),
                meta: {index: 2, keepAlive: true, fullScreen: true},
                redirect: '/user_mgmt/user/punch_record',
                children: [
                    {
                        path: 'punch_record',
                        name: 'punch_record',
                        component: () => import('./pages/punch_record/panel/UserPunchRecordPanel'),
                        meta: {index: 3, keepAlive: true, fullScreen: true}
                    },
                    {
                        path: 'punch_instance_list',
                        name: 'punch_instance_list',
                        component: () => import('./pages/punch_record/panel/PunchInstanceListPanel'),
                        meta: {index: 4, keepAlive: true, fullScreen: true}
                    },
                    {
                        path: 'scheme_instance_list',
                        name: 'scheme_instance_list',
                        component: () => import('./pages/scheme_instance/panel/SchemeInstanceListPanel'),
                        meta: {index: 4, keepAlive: true, fullScreen: true}
                    },
                    {
                        path: 'questionnaire_record_list',
                        name: 'questionnaire_record_list',
                        component: () => import('./pages/questionnaire_record/panel/QuestionnaireRecordListPanel'),
                        meta: {index: 4, keepAlive: true, fullScreen: true},
                    },
                    {
                        path: 'prescription_list',
                        name: 'prescription_list',
                        component: () => import('./pages/prescription/panel/PrescriptionListPanel'),
                        meta: {index: 4, keepAlive: true, fullScreen: true},
                    },
                    {
                        path: 'report_list',
                        name: 'user_report_list',
                        component: () => import('./pages/report/panel/ReportListPanel'),
                        meta: {index: 4, keepAlive: true, fullScreen: true},
                    },
                    {
                        path: 'consultancy_list',
                        name: 'consultancy_list',
                        component: () => import('./pages/consultancy/ConsultancyList'),
                        meta: {index: 4, keepAlive: true, fullScreen: true}
                    }
                ]
            },
            {
                path: 'appointment_list',
                name: 'appointment_list',
                component: () => import('./pages/user_bak/AppointmentList'),
                meta: {index: 2, keepAlive: true}
            },
            {
                path: 'scheme_trade_list',
                name: 'scheme_trade_list',
                component: () => import('./pages/trade/SchemeTradeList'),
                meta: {index: 1, keepAlive: true}
            }
        ]
    },
    {
        path: '/system_setting',
        name: 'system_setting',
        component: () => import('./pages/system/SystemSetting'),
        redirect: '/system_setting/account_list',
        children: [
            {
                path: 'plugin_list',
                name: 'plugin_list',
                component: () => import('./pages/plugin/PluginList'),
                meta: {index: 1, keepAlive: true}
            },
            {
                path: 'plugin',
                name: 'plugin',
                component: () => import('./pages/plugin/Plugin'),
                meta: {index: 2, keepAlive: true}
            },
            {
                path: 'account_list',
                name: 'account_list',
                component: () => import('./pages/system/account/AccountList'),
                meta: {index: 1, keepAlive: true}
            },
            {
                path: 'account',
                name: 'account',
                component: () => import('./pages/system/account/Account'),
                meta: {index: 2, keepAlive: true}
            },
            {
                path: 'organization_list',
                name: 'organization_list',
                component: () => import('./pages/system/organization/OrganizationList'),
                meta: {index: 1, keepAlive: true}
            },
            {
                path: 'organization',
                name: 'organization',
                component: () => import('./pages/system/organization/Organization'),
                meta: {index: 1, keepAlive: true}
            }
        ]
    },
    {
        path: '/mt/weight',
        name: 'mt_weight',
        component: () => import("./pages/minitools/weight/MiniToolWeight"),
    },
    {
        path: '/mt/weight_gravida',
        name: 'mt_gravida_weight',
        component: () => import('./pages/minitools/gravida_weight/MiniToolGravidaWeight'),
    },
    {
        path: '/mt/fetal_movement',
        name: 'mt_fetal_movement',
        component: () => import('./pages/minitools/fetalmovement/MiniToolFetalMovement'),
    },
    {
        path: '/mt/blood_glucose',
        name: 'mt_blood_glucose',
        component: () => import('./pages/minitools/blood_glucose/MiniToolBloodGlucose'),
    }
]

export default routers;