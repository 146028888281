<template>
  <div>
    <div class="container layout-horizontal layout-middle">
      <div class="layout-self-stretch layout-vertical layout-center layout-middle"
           style="background-color: #f9f3fc; width: 60%;">
        <el-image :src="require('../../assets/cover.png')" fit="scale-down"
                  style="width: 80%; height: 80%; max-height: 500px;"></el-image>
      </div>
      <div class="layout-vertical layout-center layout-middle layout-flexible"
           style="min-width: 500px; padding-bottom: 100px;">
        <div class="layout-horizontal margin-bottom layout-middle">
          <el-image class="image-logo" :src="require('../../assets/logo.png')" fit="cover"
                    style="width: 64px; height: 64px;"></el-image>
          <div class="margin-left font-align-left">
            <div class="font-size-extra-large">缮记</div>
            <div class="font-color-secondary">专业营养指导平台</div>
          </div>
        </div>
        <div class="font-color-secondary margin-top font-align-left padding-top" style="width: 320px;">登录后使用</div>
        <div>
          <div>
            <ys-input placeholder="请输入账号" class="input margin-top-small" placeholder-class="font-size-large"
                      v-model="account" hollow
                      @input="refreshLoginButtonVisible" size="large">
            </ys-input>
          </div>
          <div>
            <ys-input class="input margin-vertical" placeholder-class="font-size-large" placeholder="请输入密码"
                      show-password
                      v-model="password" hollow
                      @input="refreshLoginButtonVisible" @keyup.enter.native="submitLogin()" size="large">
            </ys-input>
          </div>
        </div>
        <div class="margin-top-small"></div>
        <ys-button class="btn-submit margin-top " size="large" :class="{disabled: !loginButtonEnable}"
                   @click="submitLogin"
                   lighting
                   :disabled="!loginButtonEnable">
          <span>登  录</span>
        </ys-button>
      </div>
    </div>
    <div class="font-color-placeholder font-align-center" style="margin-top: -64px;">
      <!---->
      <div style="width:300px;margin:0 auto; padding:20px 0; display: inline-block;">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019507"
           style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img
              :src="require('../../assets/web-police.jpg')" style="float:left;"/>
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">沪公网安备
            31011502019507号</p></a>
      </div>
      <div style="display: inline-block">
        <a target="_blank" href="https://beian.miit.gov.cn/"
           style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-left: 50px;">
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">
            沪ICP备2022026178号-2</p></a>
      </div>
    </div>
  </div>
</template>

<script>
import httpapi from "../../assets/javascript/httpapi";
import YsInput from "../../components/wedigets/YsInput";
import YsButton from "../../components/wedigets/YsButton";
import OrganizationUtils from "@/assets/javascript/organization-utils";

function doLogin(account, password) {
  this.$login({
    account,
    password
  }).then(() => {
    doLoadAccountExtralData.bind(this)();
  }).catch(res => {
    switch (res.errcode) {
      case this.Errcode.ACCOUNT_NOT_EXISTS:
        this.$message.error('账号不存在');
        break;
      case this.Errcode.INVALID_PASSWORD:
        this.$message.error('密码错误');
        break;
      default:
        this.$message.error('系统错误');
    }
  });
}

function doLoadAccountExtralData() {
  this.$loadAccountExtra().then((res) => {
    this.$message.success('登录成功');
    if (res.data.organizationList.length === 1) {
      OrganizationUtils.$setCurrentOrganization(res.data.organizationList[0]);
      this.$router.push({name: 'users'});
    } else {
      if (!OrganizationUtils.$getOrganization()) {
        this.$router.push({name: 'organizations_choose'})
      }
    }
    this.$emit('login');
  }).catch(() => {
    this.$logout();
    this.$message('登录失败');
  })
}

export default {
  name: "Login",
  components: {YsButton, YsInput},
  mixins: [httpapi],
  data() {
    return {
      activeIndex: '1',
      account: null,
      password: null,
      loginButtonEnable: false,
    }
  },
  watch: {},
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
    },
    refreshLoginButtonVisible() {
      this.loginButtonEnable = this.account && this.password;
    },
    submitLogin() {
      if (!this.account) {
        this.$message.warning('请输入账号');
        return;
      }
      if (!this.password) {
        this.$message.warning('请输入密码');
        return;
      }
      doLogin.bind(this)(this.account, this.password);
    }
  }

}
</script>

<style scoped>
@import "../../assets/css/style.css";

.image-logo {
  border-radius: 4px;
  overflow: hidden;
}

.input {
  width: 320px;
}

.btn-submit {
  width: 320px;
}

.container {
  height: 100vh;
  height: 100vh;
  box-sizing: border-box;
}
</style>
