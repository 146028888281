import AuditingUtils from "./auditing-utils";

/**
 * 组织机构
 * @type {number}
 */
const TYPE_ORGANIZATION = 0;
/**
 * 个人
 * @type {number}
 */
const TYPE_PERSONAL = 1;

function defaultOrganization() {
    return {
        name: null,
        avatar: null,
        introduction: null,
        type: TYPE_PERSONAL,
        closed: false,
        followingInfo: null,
    }
}

function defaultOrganizationWithPrivate() {
    let organization = defaultOrganization();
    organization.closingReason = null;
    organization.phone = null;
    organization.address = null;
    organization.certificateImage = null;
    return organization;
}

function defaultOrganizationMember() {
    return {
        organizationId: null,
        account: null,
        joinTime: null,
        exitTime: null,
        protocol: OrganizationUtils.PROTOCOL_INTERNAL,
        state: OrganizationUtils.MEMBER_STATE_NORMAL,
        status: OrganizationUtils.MEMBER,
    }
}

function defaultOrganizationAuditing() {
    return {
        type: AuditingUtils.TYPE_MODIFY,
        time: new Date(),
        organizationId: 0,
        name: null,
        avatar: null,
        introduction: null,
        phone: null,
        address: null,
        certificateImage: null,
        state: AuditingUtils.STATE_WAIT,
        rejectionReason: null,
        finishedTime: null,
    }
}

function doSetCurrentOrganziation(organization) {
    if (organization) {
        window.localStorage.setItem('organization', JSON.stringify(organization));
    } else {
        window.localStorage.removeItem('organization');
    }
    window.eventBus.$emit('organization_changed', organization);
}

function doGetCurrentOrganization() {
    let organizationStr = window.localStorage.getItem('organization');
    if (organizationStr) {
        return JSON.parse(organizationStr);
    } else {
        return null;
    }
}


const OrganizationUtils = {
    TYPE_ORGANIZATION: TYPE_ORGANIZATION,
    TYPE_PERSONAL: TYPE_PERSONAL,
    ORGANIZATION_NAME_MAX_LENGTH: 32,
    CLOSING_REASON_MAX_LENGTH: 200,
    INTRODUCTION_MAX_LENGTH: 32,

    MANAGER: 0,
    SUPER_MANAGER: 1,
    MEMBER: 2,

    PROTOCOL_INTERNAL: 0,
    PROTOCOL_THIRD_PART: 1,

    MEMBER_STATE_NORMAL: 0,
    MEMBER_STATE_EXITED: 1,

    defaultOrganization: defaultOrganization,
    defaultOrganizationWithPrivate: defaultOrganizationWithPrivate,
    defaultOrganizationMember: defaultOrganizationMember,
    defaultOrganizationAuditing: defaultOrganizationAuditing,

    $setCurrentOrganization(organization) {
        doSetCurrentOrganziation(organization);
    },
    $getOrganization() {
        return doGetCurrentOrganization();
    },
    data() {
        return {}
    },
    methods: {
        getCurrentOrganizationId() {
            return 0;
        }
    }
}

export default OrganizationUtils;